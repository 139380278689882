.promotion-item {
    max-width: 360px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);

    cursor: pointer;

    &__image {
        max-width: 360px;
        width: 100%;
        overflow: hidden;
        max-height: 360px;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__title {
        text-align: center;
        font-size: 24px;
        line-height: 28px;
        color: #fff;
        margin-top: 10px;
    }

    &__short {
        padding-left: 10px;
        padding-right: 10px;
        height: 100px;
        overflow: hidden;
        position: relative;
        z-index: 0;
        color: #fff;
        margin-bottom: 20px;
    }

    &__short::before {
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        z-index: 2;
        right: 10px;
        content: "Подробнее";
        color: #FF6B00;
        font-size: 13px;
        border-bottom: 1px dashed #FF6B00;
    }

    &__short::after {
        position: absolute;
        display: block;
        content: "";
        width: 100%;
        height: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        text-align: right;
        background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #000 40%);
    }
}
// inter font
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/static/Inter-Light.ttf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/static/Inter-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/static/Inter-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/static/Inter-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/static/Inter-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/static/Inter-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/static/Inter-Black.ttf');
    font-weight: 900;
    font-style: normal;
}

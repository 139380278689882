@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variables.scss";

.product-item {
  height: 430px;
  width: 240px;
  padding: 10px;
  background: $colorWhite;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  position: relative;

  .router,
  .router:hover {
    color: $colorBlack !important;
  }

  &__image {
    margin-bottom: 18px;
    height: 120px;
    display: flex;
    justify-content: center;
    overflow: hidden;

    img {
      position: absolute;
      height: 120px;
      object-fit: cover;
      transition: .3s;
      cursor: pointer;
    }
  }

  &__title {
    text-align: center;
    @include Bold;
    font-size: $fontSize18;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  &__description {
    height: 40px;
    overflow: hidden;
    font-size: 14px;
    color: #666;
    word-break: break-word;
  }

  &__short-fade {
    position: relative;
    left: 0;
    width: 100%;
    right: 0;
    text-align: right;
    top: -10px;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff);

    .action::before {
      cursor: pointer;
      position: relative;
      top: 15px;
      content: "Подробнее";
      color: #FF6B00;
      font-size: 13px;
      border-bottom: 1px dashed #FF6B00;
    }
  }

  &__variants {
    display: flex;
    justify-content: space-evenly;
  }



  &__count {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  &__count-btns {
    display: flex;
    justify-content: space-between;
    width: 80px;
  }

  &__count-btn {
    cursor: pointer;
  }

  &__btn-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btn-sale {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 97px;
    height: 36px;
    margin-top: 12px;
  }

  &__btn {
    border-radius: 100px;
    color: #fff;
    width: 100px;
    height: 37px;
    text-align: center;
    display: flex;
    background-color: #FF6B00;
    align-items: center;
    cursor: pointer;
  }

  &__size {
    margin-top: 10px;
    font-style: normal;
    font-size: 17px;
    line-height: 19px;
    text-align: center;
    color: #FF6B00;
  }

  &__sale_price {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    font-size: 18px;
    color: #000;
    @include Bold;
  }

  &__price {
    text-decoration: line-through;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: #858585;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }

  &__icon img {
    width: 34px;
    height: 34px;
  }

  &__image-basket {
    margin: 0 5px;
    cursor: pointer;
    transition: .2s;
    -webkit-transition: .2s;
    -moz-transition: .2s;
  }

  &__image-item {
    position: absolute;
  }

  &__image-item-absolute {
    width: 100%;
    height: 100vh;
    background: #000;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
  }

  &__image-item-absolute-block {
    position: absolute;
    display: none;
  }

  &__image-item-block {
    animation: block 1 0.7s linear;
    opacity: 0;
  }

  @keyframes block {
    0% {
      opacity: 0.1;
    }

    100% {
      opacity: 1;
    }
  }

  &__image-item_active {
    animation: active 1 0.7s linear;
    -moz-animation: active 1 0.7s linear;
    -webkit-animation: active 1 0.7s linear;
  }

  @keyframes active {

    0%,
    80%,
    100% {
      position: absolute;
    }

    0% {
      height: 120px;
      right: 30px;
      bottom: 280px;
    }

    80% {
      height: 30px;
      right: 50px;
      bottom: 25px;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      opacity: 0.2;
    }

    100% {
      height: 20px;
      right: 50px;
      bottom: 28px;
      opacity: 0;
    }
  }

  &__image-basket_active {
    animation: active_basket 1s 1 linear;
    -moz-animation: active_basket 1s 1 linear;
    -webkit-animation: active_basket 1s 1 linear;
  }

  @keyframes active_basket {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(-15deg);
    }

    50% {
      transform: rotate(15deg);
    }

    75% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-15deg);
    }
  }
}

@media screen and (max-width: 500px) {
  .product-item {
    margin: 0;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 70%;
    padding: 10px;
    background: $colorWhite;
    position: relative;

    .router,
    .router:hover {
      color: $colorBlack !important;
    }

    &__image {
      margin-bottom: 18px;
      display: flex;
      justify-content: center;
      height: 120px;

      img {
        position: absolute;
        object-fit: cover;
      }
    }

    &__title {
      text-align: center;
      font-size: $fontSize18;
      font-weight: 700;
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
    }

    &__description {
      text-align: center;
      font-size: $fontSize12;
      font-weight: 700;
      margin-top: 2px;
    }

    &__variants {
      display: flex;
      justify-content: space-evenly;
    }

    &__variant {
      display: flex;
      align-items: center;

      label {
        font-style: normal;
        font-size: 12px;
        line-height: 14px;
        margin-left: 5px;
      }

      input[type="radio"] {
        appearance: none;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid black;
        outline: none;
        position: relative;
      }

      input[type="radio"]:checked:before {
        width: 8px;
        height: 8px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        position: absolute;
        background-color: $colorBlack;
        content: "";
        display: inline-block;
        visibility: visible;
      }
    }

    &__count {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__count-btns {
      display: flex;
      justify-content: space-between;
      width: 80px;
    }

    &__count-btn {
      cursor: pointer;
    }

    &__btn-group {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__price {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      color: #858585;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
    }

    &__icon img {
      width: 34px;
      height: 34px;
    }

    &__image-basket {
      margin: 0 5px;
      cursor: pointer;
    }
  }
}
@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/fonts-face.scss";

.order-processing-contact {
    input {
        width: 100%;
        padding: 13px;
        @include borderRadius10;
        border-color: $colorBlack;
        resize: none;
        outline: none;
    }
   
    input,
    input::placeholder,
    textarea {
        font-size: 18px;
        line-height: 22px;
        color: #000000;
    }
    label {
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }
    &__type {
        @include centerFlex;
        margin-top: 50px;
    }

    &__phone,
    &__surname,
    &__address-apartment,
    &__address-entrance {
        margin-top: 40px !important;
    }

    &__address {
        margin-top: 30px;
    }
    &__type {
        span,
        label {
            font-size: 18px;
            line-height: 22px;
            color: #000000;
        }
    }
    &__type-delivery,
    &__type-pickup {
        input[type="radio"] {
            width: 30px;
            height: 30px;
            appearance: none;
            @include borderRadiusCircle;
            border: 1px solid $colorOrange;
            outline: none;
            position: relative;
        }
        input[type="radio"]:checked:before {
            width: 16px;
            height: 16px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            @include borderRadiusCircle;
            background-color: $colorOrange;
            content: "";
            display: -moz-inline-block;
            display: inline-block;
            visibility: visible;
        }
        @include displayFlex;
        margin-left: 30px;
        margin-top: 10px;

        label {
            margin-left: 10px;
        }
    }

    &__address-residence,
    &__address-home,
    &__address-entrance,
    &__address-apartment,
    &__address-coment {
        width: 100%;
    }

    &__address-coment {
        margin-top: 40px !important;

        textarea {
            width: 100%;
            @include centerFlex;
            padding: 13px;
            border: 3px solid $colorBlack;
            @include borderRadius10;
            resize: none;
            outline: none;
        }
    }
}

@media screen and (max-width: 680px) {
    .order-processing-contact {
        margin: 0 auto;
        &__address-form {
            @include displayFlex;
            flex-direction: column;
            -webkit-flex-direction: column;
        }
        &__address-home {
            margin-top: 40px;
        }

        &__type {
            @include displayFlex;
            -webkit-flex-direction: column;
            flex-direction: column;
            justify-content: flex-start;
            -webkit-justify-content: flex-start;
            -webkit-align-items: flex-start;
            margin: 5px;
        }
        &__type-text {
            font-size: 24px;
            font-weight: 600;
        }

        &__type-delivery,
        &__type-pickup {
            margin: 5px 10px;
        }
    }
}

@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixin.scss";

.sign-in {
    &__message-error {
        color: #D8000C !important;
        font-size: $fontSize13;
    }
    &__title {
        h1 {
            font-size: $fontSize36 !important;
            line-height: 20px !important;
            text-align: center;
        }
    }
    &__form-group {
        display: flex;
        flex-direction: column;
    }
    &__attempt {
        span:nth-child(1) {
            color: $colorOrange;
            cursor: pointer;
        }
        text-align: center;
    }
    &__input {
        width: 100%;
        box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.25);
        margin: 15px 0px;
        border-radius: 50px;
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        outline: none;
        border: none;
        padding-left: 20px;
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: $fontSize18;
        font-weight: 700;
    }
    &__input::placeholder {
        font-size: $fontSize18;
        font-weight: 700;
    }
    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            margin: 10px;
            width: 100%;
            height: 50px;
            border-radius: 50px;
            -moz-border-radius: 50px;
            -webkit-border-radius: 50px;
            color: white;
            outline: none;
            border: none;
            background-color: $colorOrange;
            font-size: $fontSize36 !important;
            line-height: 20px !important;
        }
    }
    &__btn-form {
        width: 200px;
    }
    &__register {
        text-align: center;
        p:nth-child(2) {
            color: $colorOrange;
            cursor: pointer;
        }
    }
}


.product-related {
    height: 100%;
    width: 30%;
    padding: 10px 10px 71px;
    border-radius: 10px;
    background: #fff;
    margin-bottom: 0;
    position: relative;
    border: 2px solid transparent;

    &__img {
        aspect-ratio: 1/1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            width: 100%;
        }
    }

    h4 {
        font-size: 14px;
        margin-top: 0;
        margin-bottom: .25rem !important;
    }

    &__bying {
        position: absolute;
        bottom: 10px;
        left: 10px;
        right: 10px;
    }

    &__price {
        font-size: 14px;
        margin-bottom: .5rem !important;
        font-weight: 700;
        margin-top: .5rem;
    }
    button {
        border-radius: 10px;
        outline: none;
        border: 1px solid #FF6B00;
        background-color: #fff;
        color: #FF6B00;
    }
}
@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixin.scss";

.order-payment-status {
  display: flex;
  flex-direction: column;
  padding-bottom: 180px;
  justify-content: center;

  &__order-status {
    font-weight: 700;
    font-size: 18px;
  }

  &__order-text {
    text-align: center;
  }

  &__content {
    padding: 80px 25px;
    background-color: #fff;
    @include borderRadius10;
    max-width: 600px;
    margin: 0 auto;
  }

  &__receipt {
    display: flex;
    justify-content: center;
  }
}
@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixin.scss";

.home-page {
    padding-bottom: 180px;
    position: relative;
    &__title {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__text {
        font-weight: 700;
        font-size: 64px;
        line-height: 72px;
        text-align: center;
        color: #fff;
    }
    &__up {
        cursor: pointer;
    }
    &__iconUp{
        position: fixed;
        top: 70%;
        z-index: 20;
    }
    &__cart {
        border-radius: 100px;
        background-color: #f85c12;
        padding: 8px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 15px;
        cursor: pointer;
    }

    &__cart-icon {
        width: 27px;
        height: 23px;
        position: relative;
        img {
            width: 100%;
            
        }
    }

    &__cart-count {
        position: absolute;
        top: -13px;
        left: 59%;
        text-align: center;
        font-size: 18px;
        color: #000;
        padding-right: 15px;
    }

    &__total {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 5px;
        gap: 10px;
        background-color: #fff;
        position: sticky;
        width: 100%;
        top: 0;
        z-index: 10;
        .a {
            color: #000;
            font-size: 18px;
            position: relative;
            cursor: pointer;
        }

        .a:hover {
            color: #000;
        }
        .a::before,
        .a::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 1px;
            width: 0;
            background: #000;
            transition: 400ms ease all;
            -webkit-transition: 400ms ease all;
            -moz-transition: 400ms ease all;
        }

        .a:after {
            right: inherit;
            top: inherit;
            left: 0;
            bottom: 1px;
        }

        .a:hover::before,
        .a:hover::after {
            width: 100%;
            transition: 800ms ease all;
            -webkit-transition: 800ms ease all;
            -moz-transition: 800ms ease all;
        }
    }

    .home-page {

        &__image10,
        &__image26,
        &__image128,
        &__image22,
        &__image129,
        &__image19,
        &__image25 {
            position: absolute;
            z-index: 1;
        }

        &__image128 {
            top: 55%;
            width: 500px;
            left: 33%;
        }

        &__image26 {
            top: 34%;
        }

        &__image129 {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__image10 {
            top: 0;
            width: 500px;
            left: 100%;
            transform: translateX(-100%);
        }

        &__image25 {
            top: 344px;
            left: 59%;
            width: 500px;
        }

        &__image19 {
            width: 300px;
            top: 400px;
            left: 70%;
        }

        &__image22 {
            width: 300px;
            top: 80%;
            left: 43%;
        }
    }

    &__top {
        z-index: 2;

        h1::first-line,
        h1 {
            font-size: $fontSize88;
            line-height: 88px;
            letter-spacing: 1.92px;
        }

        h1 {
            color: $colorWhite;
        }

        h1::first-line {
            color: #f85c12;
        }

        p {
            font-weight: 700;
            font-size: 32px;
            line-height: 35px;
            letter-spacing: 0.2px;
            color: #f85c12;
            width: 683px;
        }
    }

    &__btn {
        @include centerFlex;
        background-color: #f85c12;
        display: inline-block;
        padding: 10px 18px;
        border-radius: 1000px;
        -moz-border-radius: 1000px;
        -webkit-border-radius: 1000px;
        cursor: pointer;

        span {
            font-weight: 700;
            font-size: 24px;
            line-height: 14px;
            color: #ffffff;
        }

        img {
            width: 24px;
            height: 14px;
            margin-left: 15px;
        }
    }

    &__bottom {
        align-self: flex-end;
        margin-top: 481px;
        z-index: 2;

        p {
            width: 470px;
            font-weight: 700;
            font-size: 32px;
            line-height: 35px;
            text-align: right;
            letter-spacing: 0.16px;
            color: #ff6b00;
        }
    }
}

@media (max-width: 1400px) {
    .home-page {

        &__image128,
        &__image22,
        &__image129 {
            display: none;
        }
    }
}

@media (max-width: 1200px) {
    .home-page {

        &__image19,
        &__image25 {
            display: none;
        }
    }
}

@media (max-width: 900px) {
    .home-page {
        padding-bottom: 64px;

        &__image26 {
            display: none;
        }

        &__image10 {
            width: 110px !important;
        }

        &__list {
            overflow-x: auto;
            white-space: nowrap;
            flex-wrap: nowrap;
            padding: 10px 10px;
            justify-content: flex-start;
            a {
                font-size: 15px;
                width: 25%;
                margin: 0 10px;
                display: inline-block;
                width: 100%;
            }
        }
        &__top {

            h1::first-line,
            h1 {
                font-size: $fontSize36;
                line-height: 30px;
            }

            h1 {
                width: 226px;
            }

            p {
                width: 205px;
                font-size: 11px;
                line-height: 10px;
            }
        }

        &__bottom {
            display: none;
        }

        &__btn {
            margin: 0 auto;
            width: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include borderRadius10;
        }
    }
}

@media (max-width: 500px) {
    .home-page {
      position: relative;
      &__cart {
        position: fixed;
        bottom: 26%;
        right: 10px;
      }
        &__total {
            display: none;  
        }
    }
}
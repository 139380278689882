@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variables.scss";

.footer {
    padding: 14px 5px 72px 5px !important;
    background-color: $colorWhite;
    position: absolute;
    width: 100%;
    &__content {
        display: flex;
        flex-direction: column;
    }
    h1 {
        font-size: $fontSize14 !important;
        line-height: 18px !important;
        letter-spacing: 0.32px !important;
    }
    &__top {
        display: flex;
        padding: 20px 0;
        align-items: center;
        overflow: hidden;
    }

    &__hr {
        height: 2px;
        background-color: $colorBlack;
    }

    &__bottom {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        a {
            font-size: $fontSize13;
            line-height: 15px;
            color: $colorBlack;
            cursor: pointer;
        }
        a:hover {
            color: $colorBlack;
        }
    }
}

@media (max-width: 993px) {
    .footer {
        padding: 14px 0;
        &__top {
            justify-content: center;
            padding: 0;
        }
    }
}

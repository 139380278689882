.menu-products {
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  margin-bottom: 50px;
  height: 100%;

  h1 {
    margin-top: 62px;
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 73px;
  }

  &__item {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
  }
}

.menu-products-adap {
  display: none;
  h1 {
    margin-top: 62px;
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 73px;
  }
}


@media (max-width: 800px) {
  .menu-products {
    display: none;
  }

  .menu-products-adap {
    display: block;
  }
}
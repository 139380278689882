@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";

.contact-page-map {
    h1 {
        display: none;
        font-size: $fontSize24;
        color: $colorWhite;
    }
    iframe {
        width: 650px;
        height: 300px;
        border: none;
    }
}
@media screen and (max-width: 991px) {
    .contact-page-map {
        margin-top: 30px;
        h1 {
            display: block;
        }
    }
}

@media  (max-width: 768px) {
    .contact-page-map {
        iframe {
            width: 500px;
            height: 300px;
        }
    }
}

@media  (max-width: 576px) {
    .contact-page-map {
        iframe {
            width: 400px;
            height: 250px;
        }
    }
}

@media  (max-width: 420px) {
    .contact-page-map {
        iframe {
            width: 350px;
            height: 225px;
        }
    }
}

@media  (max-width: 370px) {
    .contact-page-map {
        iframe {
            width: 100%;
            height: 200;
        }
    }
}

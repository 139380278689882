.about-texts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 105px;
  gap: 50px;
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #fff;
    font-style: normal;
  }

  &__item-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
  }

  &__item-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: 500px) {
  .about-texts {
    display: none;
  }
}
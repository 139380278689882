@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";

.order-processing-list {
    flex-direction: column;
    -webkit-flex-direction: column;
    @include displayFlex;
    margin-top: 20px;
    &__wrapper {
        padding: 10px;
        height: 300px;
        overflow: scroll;
        box-shadow: inset 0px -15px 20px -15px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: inset 0px -15px 20px -15px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: inset 0px -15px 20px -15px rgba(0, 0, 0, 0.5);

    }
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }
    &__title {
        @include displayFlex;
        align-items: center;
        -webkit-align-items: center;
        span {
            margin: 0 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            color: #000000;
        }
    }
    &__product {
        @include displayFlex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -webkit-align-items: center;
        margin: 37px 0;
    }

    &__images {
        width: 210px;
        border: 1px solid $colorBlack;
        @include centerFlex;
        padding: 3px;
        img {
            width: 100%;
        }
    }
    &__name{
        width: 100%;
        text-align: left;
        display: block;
    }
    &__price {
        width: 20%;
    }
    &__right {
        @include displayFlex;
        width: 70%;
        justify-content: space-around;
        -webkit-justify-content: space-around;
    }

    &__btn-group {
        width: 20%;
        @include displayFlex;
        flex-direction: column;
        -webkit-flex-direction: column;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
        span {
            cursor: pointer;
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
            text-align: center;
        }
    }

    &__count {
        margin: 0 8px;
        color: #ff6b00;
    }

    &__total {
        @include centerFlex;

        span {
            margin: 0 50px;
        }
    }

    &__total-price {
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0.02em;
        color: #ff6b00;
    }

    &__btn-pay {
        @include centerFlex;
        margin-top: 77px;
        button {
            outline: none;
            background: #ff6b00;
            border: 3px solid #ff6b00;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            padding: 15px 70px;
            font-style: normal;
            font-weight: 900;
            font-size: 24px;
            line-height: 28px;
            text-transform: uppercase;
            color: #ffffff;
        }
        button:active {
            background-color: #F47C0D;
        }
    }
}
@media (max-width: 900px) {
    .order-processing-list {
        &__right, &__title  {
            @include displayFlex;
            -webkit-align-items: center;
        }
        &__title {
            span {
                margin: 0 10px;
            }
        }
    }
}
@media (max-width: 680px) {
    .order-processing-list {
        &__images {
            display: none;
        }

        &__product {
            justify-content: flex-start;
            -webkit-justify-content: flex-start;
        }

        &__right {
            width: 100%;
            justify-content: space-between;
            -webkit-justify-content: space-between;
        }

        &__title {
            width: 230px;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            span {
                margin: 0;
            }
        }
        &__name,
        &__price {
            font-size: 10px;
            line-height: 12px;
        }
        &__price {
            align-self: flex-end;
            -webkit-align-self: flex-end;
        }
        &__total {
            @include displayFlex;
            justify-content: space-around;
            -webkit-justify-content: space-around;
            span {
                margin: 0;
            }
        }
        &__total-price {
            line-height: 19px;
            font-size: 24px;
        }
    }
}
@media (max-width: 300px) {
    .order-processing-list {
        &__btn-pay {
            button {
                padding: 15px 25px;
            }
        }
    }
}

@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixin.scss';

.purchase-modal {
    padding: 80px 25px;
    .thanks, .check {
        font-size: $fontSize36 !important;
        line-height: 40px;
    }
    &__title {
        max-width: 500px;
        text-align: center;
        .thanks {
            background-color: #F47C0D;
            color: $colorWhite;
            font-weight: 700;
            @include borderRadius10;
        }
    }
    &__description {
        text-align: center;
        margin-top: 38px;
        .check {
            color: $colorBlack;
        }
    }
    &__logo {
        max-width: 500px;
        img {
            width: 100%;
        }
    }
}

@media (max-width: 520px) {
    .purchase-modal {
        &__title {
            text-align: center;
            .thanks {
                font-size: 16px !important;
            }
        }
    }
}
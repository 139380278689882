@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variables.scss";

.contact-page {
    padding-bottom: 180px;
    background-color: $colorBlack;

    &__content {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        margin-bottom: 250px;
    }

    &__title {
        margin-bottom: 113px;
        text-align: center;
        font-size: 72px;
        color: $colorWhite;
    }

    &__map {
        margin-bottom: 230px;
    }
}

@media (max-width: 991px) {
    .contact-page {
        padding-bottom: 64px;
        &__title {
            margin-bottom: 24px;
            font-size: $fontSize24;
        }
        &__content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 80px;
        }
    }
}

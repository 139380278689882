@import '../../../assets/scss/mixin.scss';
@import '../../../assets/scss/variables.scss';

.header-contact {
    background-color: $colorBlack;
    padding: 20px 0;

    &__content {
        padding-left: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__left {

        h1 {
            color: $colorWhite;
            font-size: $fontSize14;
        }

        select {
            background: none;
            outline: none;
            border: none;
            color: $colorOrange;
        }

        option {
            background: none;
            outline: none;
            border: none;
            color: $colorOrange;
        }
    }

    &__info {
        display: flex;
        justify-content: space-evenly;
        align-items: baseline;
        width: 100%;

    }

    &__icon {
        width: 35px;
        height: 35px;
        margin-right: 5px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__item {
        display: flex;
        align-items: flex-start;
    }

    &__text {
        display: flex;
        flex-direction: column;

        span {
            font-family: 'Inter';
            font-style: normal;
        }
    }

    &__text-top {
        font-size: 19px;
        line-height: 23px;
        color: #fff;
    }

    &__text-bottom {
        font-size: 16px;
        line-height: 19px;
        color: #FF6B00;
    }

    &__btn {
        button {
            outline: none;
            border: none;
            border-radius: 100px;
            width: 145px;
            height: 38px;
            background-color: #FF6B00;
            color: #fff;
            font-family: 'Inter';
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
        }

        button:active {
            background-color: #ae4d08;
        }
    }


}


@media screen and (max-width: 991px) {
    .header-contact {
        padding-top: 34px;
        background-color: $colorBlack;

        &__content {
            display: none;
        }
    }
}
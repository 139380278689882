@import "../../assets/scss/fonts-face.scss";
@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variables.scss";

.basket-page {
    padding-bottom: 180px;
    &__title, &__empty {
        text-align: center;
        font-weight: 700;
        font-size: 72px;
        line-height: 84px;
        color: $colorWhite;
    }
    &__product {
        display: flex;
        margin-top: 111px;
        max-width: 1000px;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }

    &__product {
        margin-bottom: 50px;
    }

    &__order,
    &__total {
        font-weight: 700;
        font-size: 48px;
        line-height: 54px;
        color: $colorWhite;
    }

    &__total {
        margin-top: 35px;
    }

    &__apply-promo {
        width: 400px;
        height: 100px;
        border: 1px solid $colorOrange;
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
        @include borderRadius10;
        span,
        input,
        input::placeholder {
            font-weight: 700;
            font-size: 30px;
            line-height: 34px;
        }
        span:active {
            background-color: #F47C0D;
        }
        input {
            text-transform: uppercase;
        }
        span {
            background-color: $colorOrange;
            width: 50%;
            height: 100%;
            @include centerFlex;
            cursor: pointer;
            color: $colorWhite;
        }

        input,
        input::placeholder {
            color: $colorWhite;
        }

        input::placeholder {
            text-transform: none;
        }

        input {
            background-color: $colorBlack;
            outline: none;
            border: none;
            width: 50%;
            padding-left: 19px;
            text-transform: uppercase;
        }
    }
    &__promo-product {
        background-color: #fff;
        border-radius: 20px;
        width: 100%;
        padding: 5px;
        display: flex;
    }
    &__promo-product-img {
        max-width: 211px;
        width: 100%;
        margin-right: 20px;
        img {
            width: 100%;
            border-radius: 5px;
        }
    }
    &__promo-product-present,
    &__promo-product-size{
        color: #F47C0D;
    }
    &__promo-product-size {
        margin-right: 10px;
    }
    &__sale {
        margin-top: 193px;
        margin-left: 40px;
    }

    &__pay-order {
        margin-top: 120px;
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 20px;
    }

    &__pay {
        background: $colorOrange;
        border: 1px solid $colorOrange;
        box-sizing: border-box;
        width: 400px;
        height: 100px;
        @include borderRadius10;
        span {
           font-weight: 700;
            font-size: 36px;
            line-height: 40px;
            color: $colorWhite;
            @include centerFlex;
            height: 100%;
            cursor: pointer;
        }
        //span:active {
        //    background-color: black;
        //}
    }

    &__swiper {
        margin-bottom: 350px;
        margin-top: 172px;
    }
}

@media (max-width: 1000px) {
    .basket-page {
        &__products {
            justify-content: space-evenly;
        }
    }
}

@media (max-width: 990px) {
    .basket-page {
        &__sale {
            margin-top: 22px;
        }

        &__apply-promo,
        &__pay {
            margin: 10px;
        }

        &__pay-order {
            margin-top: 26px;
        }

        &__swiper {
            margin-top: 23px;
            display: none;
        }
    }
}

@media (max-width: 780px) {
    .basket-page {
        &__sale {
            margin-top: 22px;
        }

        &__pay-order {
            flex-direction: column;
            align-items: center;
            margin-top: 26px;
        }

        &__pay {
            margin-top: 25px;
        }

        &__order,
        &__total {
            font-size: 27px;
            line-height: 20px;
        }
    }
}

@media (max-width: 450px) {
    .basket-page {
        &__apply-promo,
        &__pay {
            width: 250px;
            height: 40px;

            span,
            input,
            input::placeholder {
                font-size: 18px;
                line-height: 20px;
            }
        }

        &__order,
        &__total {
            font-size: 16px;
            line-height: 18px;
        }
    }
}

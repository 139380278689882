@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variables.scss";

.order-processing {
    background-color: $colorWhite;
    padding: 64px 100px;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin: 80px 0;
    max-width: 993px;
}
@media (max-width: 900px) {
    .order-processing {
        padding: 20px;
    }
}
@media screen and (max-width: 680px) {
    .order-processing {
        padding: 15px;
        margin: 48px 0;
    }
}

@import '../../../assets/scss/mixin.scss';
@import '../../../assets/scss/variables.scss';

.contact-page-adress{
    &__text{
        width: 250px;
        margin-right: 50px;
        img{
            width: 25px;
        }
        
        h1, a{
            font-weight: 700;
            font-size: $fontSize16;
            margin-left: 10px;
            color: $colorWhite;
            text-decoration: none;
        }
        ul{
            list-style: none;
            padding: 0;
            width: 100%;
            margin: 0;
        }
        li{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;
        }
        a:hover{
            color: $colorOrange;
        }
    }
}
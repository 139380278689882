.loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-absolute {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
}

.history-order-page {
    &__title {
        font-weight: 700;
        font-size: 64px;
        line-height: 72px;
        text-align: center;
        color: #fff;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;
        padding: 50px 0;
    }

    &__item {
        background-color: #fff;
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
        padding: 10px;
        border-radius: 10px;
        max-width: 1000px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: #636e72;

    }

    &__not-paid {
        color: #d63031;
    }

    &__paid {
        color: #26de81;
    }

    &__item-btn {
        display: flex;
        flex-direction: column;
        gap: 20px;

        button {
            border: 1px solid #f85c12;
            outline: none;
            background-color: #fff;
            color: #f85c12;
        }

        button:hover {
            background-color: #f85c12;
            color: #fff;
        }

        button:active {
            opacity: .5;
        }
    }

    p {
        margin: 0;
    }
}
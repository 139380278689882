.promotion-page {
    padding-bottom: 180px;
    display: flex;
    flex-direction: column;
    &__title {
        color: #fff;
        text-align: center;
        font-size: 72px;
        font-weight: 700;
        line-height: 81px;
        letter-spacing: 0.01em;
    }
    &__wrapper {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 50px;
    }
}
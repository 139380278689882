@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixin.scss";

.location {
    &__phone,
    &__address {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    &__icon {
        @include centerFlex;
        width: 16px;
        height: 16px;
        margin-right: 14px;
        img {
            width: 100%;
        }
    }
    a:hover {
        color: $colorBlack;
    }
    a, span {
        cursor: pointer;
        line-height: 20px;
        font-size: $fontSize14;
        color: $colorBlack;
    }
    span {
        width: 159px;
    }
}


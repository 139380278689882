// color//
$colorWhite: #fff;
$colorBlack: #000;
$colorOrange: #FF6B00;
$colorBlackGray: #263238;
$colorYellow: hsl(48, 100%, 50%);
$colorRedY: #FF543E;

// font-size //
$fontSize144: 144px;
$fontSize96: 96px;
$fontSize88: 88px;
$fontSize64: 64px;
$fontSize50: 50px;
$fontSize48: 48px;
$fontSize36: 36px;
$fontSize30: 30px;
$fontSize24: 24px;
$fontSize20: 20px;
$fontSize18: 18px;
$fontSize16: 16px;
$fontSize15: 15px;
$fontSize14: 14px;
$fontSize13: 13px;
$fontSize12: 12px;
$fontSize10: 10px;

@import '../../assets/scss/mixin.scss';
@import '../../assets/scss/variables.scss';


.slider {
    margin: 20px 0;
    width: 100%;
}

@media (max-width: 900px) {
    .slider {
        margin: 20px 0;
    }
}
@import '../../../assets/scss/mixin.scss';
@import '../../../assets/scss/variables.scss';

.header-search {
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &__input-form {
        margin-top: 61px;
        margin-bottom: 43px;
        background-color: $colorWhite;
        width: 772px;
        height: 48px;
        border-radius: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;

        input {
            outline: none;
            border: none;
            width: 100%;
        }

        img {
            margin-left: 10px;
            cursor: pointer;
            width: 16px;
        }
    }

    &__busket-icon {
        z-index: 4;
        position: fixed;
        width: 50px;
        left: 95% !important;
        @include centerFlex;
        cursor: pointer;
        margin-bottom: -10px;
        transition: 0.2s;
        -moz-transition: 0.2s;
        -webkit-transition: 0.2s;
        img{
            width: 50px;
        }
        h1 {
            width: 30px;
            height: 30px;
            background-color: $colorOrange;
            font-size: $fontSize18;
            @include centerFlex;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            position: absolute;
            top: 0;
            left: 26px;
            color: #fff;
        }
    }
    &__busket-icon:active {
        transform: translateY(-3px);
    }
}


@media screen and (max-width: 1200px) {
    .header-search {
        &__busket-icon {
            left: 30px;
        }
    }
}

@media screen and (max-width: 991px) {
    .header-search {
        &__busket-icon {
            display: none;
        }
    }
}
@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixin.scss";

.order-page {
    display: flex;
    flex-direction: column;
    padding-bottom: 180px;
    &__title {
        font-weight: 700;

        text-align: center;
        font-size: 72px;
        line-height: 81px;
        letter-spacing: 0.01em;
        color: #ffffff;
    }
    &__empty {
        margin-bottom: 100px;
        font-weight: 700;
        text-align: center;
        font-size: 52px;
        line-height: 6px;
        letter-spacing: 0.01em;
        color: #fff;
    }
    &__content {
        align-self: center;
    }
}
@media (max-width: 900px) {
    .order-page {
        padding-bottom: 64px;
    }
}
@media (max-width: 600px) {
    .order-page {
        &__title {
            font-size: 24px;
            line-height: 28px;
        }
        &__content {
            align-self: auto;
        }
    }
}

@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixin.scss";

.footer-menu-group {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__home,
  &__menu,
  &__checkout,
  &__contact,
  &__registration,
  &__list {
    display: flex;
    flex-direction: column;
  }
  .title,
  a {
    position: relative;
  }
  .title {
    color: $colorBlack;
    font-size: $fontSize14;
    line-height: 18px;
    letter-spacing: 0.32px;
  }
  &__auth {
    cursor: pointer;
    font-size: $fontSize13;
    line-height: 22px;
    letter-spacing: 0.14px;
    color: #ff6b00 !important;
    position: relative;
  }
  &__auth:hover {
    color: #ff6b00;
  }

  &__auth::before,
  &__auth::after {
    content: "";
    position: absolute;
    top: -3px;
    right: 0;
    height: 1px;
    width: 0;
    background: $colorOrange;
    transition: 400ms ease all;
    -moz-transition: 400ms ease all;
    -webkit-transition: 400ms ease all;
  }
  &__auth:after {
    right: inherit;
    top: inherit;
    left: 0;
    bottom: -3px;
  }
  &__auth:hover::before,
  &__auth:hover::after {
    width: 100%;
    transition: 800ms ease all;
    -moz-transition: 800ms ease all;
    -webkit-transition: 800ms ease all;
  }
  &__list {
    span {
      margin: 11px 0px;
    }
    a {
      cursor: pointer;
      font-size: $fontSize13;
      line-height: 22px;
      letter-spacing: 0.14px;
      color: #ff6b00 !important;
      height: 23px;
    }
    a:hover {
      color: #ff6b00;
    }

    a::before,
    a::after {
      content: "";
      position: absolute;
      top: -5px;
      right: 0;
      height: 1px;
      width: 0;
      background: $colorOrange;
      transition: 400ms ease all;
      -moz-transition: 400ms ease all;
      -webkit-transition: 400ms ease all;
    }
    a:after {
      right: inherit;
      top: inherit;
      left: 0;
      bottom: -5px;
    }
    a:hover::before,
    a:hover::after {
      width: 100%;
      transition: 800ms ease all;
      -moz-transition: 800ms ease all;
      -webkit-transition: 800ms ease all;
    }
  }
}

@media (max-width: 993px) {
  .footer-menu-group {
    display: none;
  }
}

@import "../../../assets/scss/fonts-face.scss";
@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";

.order-processing-cash {
  input {
    width: 100%;
    padding: 13px;
    @include borderRadius10;
    border-color: $colorBlack;
    resize: none;
    outline: none;
  }

  &__promo-code {
    max-width: 100%;
    @include centerFlex;
  }

  &__promo-code_item {
    width: 333px;
    margin-top: 40px;

    input {
      text-transform: uppercase;
    }

    label,
    input::placeholder {
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    label {
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }

  &__group {
    margin-top: 36px;
    @include centerFlex;


    span {
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    input[type="radio"] {
      width: 30px;
      height: 30px;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      @include borderRadiusCircle;
      border: 1px solid $colorOrange;
      outline: none;
      position: relative;
    }

    input[type="radio"]:checked:before {
      width: 16px;
      height: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      @include borderRadiusCircle;
      background-color: $colorOrange;
      content: "";
      display: inline-block;
      visibility: visible;
    }

    label {
      margin-left: 10px;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
  }

  &__group-card,
  &__group-cash,
  &__group-online_payment {
    @include centerFlex;
    margin-left: 10px;
  }
}

@media screen and (max-width: 680px) {
  .order-processing-cash {
    &__promo-code_item {
      width: 100%;

      input {
        width: 100%;
      }
    }

    &__group {
      @include displayFlex;
      flex-direction: column;
      -webkit-flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      -webkit-align-items: flex-start;
      margin: 5px;
    }

    &__group_payment {
      font-size: 24px;
      font-weight: 600;
    }

    &__group-card,
    &__group-cash,
    &__group-online_payment {
      margin: 5px 10px;
    }
  }
}

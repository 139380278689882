.modal-header {
  border-bottom: 0 !important;
}
.opacity{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.4);
}
@media (max-width: 1000px) {
}

@media (max-width: 500px) {
  .modal-dialog {
    min-height: 300px !important;
    min-width: 300px !important;
  }

  .detail-page {
    &__content {
      flex-direction: column;
      position: relative;
      align-items: center;
      height: 100%;

      .product-item {
        background-color: #f1f1f1 !important;
        border-radius: 50px;
        height: auto;
        width: auto
      }
    }

    &__related_products {
      justify-content: center;
    }

    &__left {
      padding: 15px 0;
    }

    &__right {
      background-color: #fff !important;
      height: 100%;
    }

    &__price {
      position: sticky;
      bottom: 60px;
      z-index: 10;
      width: 100%;

      button {
        float: right;
      }
    }
  }
}

.detail-page {
  &__content {
    display: flex;
  }

  &__left {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 300px;
    height: 295px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      transform: none;
      width: 100%;
      height: 100%;
      transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  &__right {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f1f1f1;
    overflow: scroll;
    height: 395px;
    width: 100%;
  }

  &__count {
    display: flex;
    width: 200px;
    justify-content: space-between;
    font-size: 24px;
    line-height: 28px;

    button {
      outline: none;
      border: none;
      background-color: transparent;
    }
  }

  &__related-text {
    margin-top: 20px;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
  }

  &__related_products {
    display: flex;
    flex-flow: row wrap;
    margin-left: -8px;
    margin-top: 6px;
    gap: 15px;
    z-index: 2;
    position: relative;
  }

  &__price {
    margin-top: 20px;
    float: right;

    span,
    button {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
    }

    span {
      margin-right: 100px;
      color: #000;
    }

    button {
      border-radius: 100px;
      background-color: #FF6B00;
      outline: none;
      border: none;
      color: #fff;
      width: 120px;
      height: 35px;
    }

    button:active {
      background-color: #ae4d07;
    }
  }
}
.card {
    border-bottom: 1px solid #FF6B00;
    border-radius: 0;
    padding: 8px;
    width: 100%;
    background-color: #fff;
    max-width: 100%;
    height: auto;
    box-shadow: none;
    display: flex;
    align-items: center;
}

.image {
    min-width: 130px !important;
    margin-right: 15px;
    width: 130px;
    padding: 0;
}

.image img {
    border-radius: 10px;
    position: static;
    max-width: 100%;
    max-height: 100%;
    bottom: auto;
    left: 0;
    -webkit-transform: translate(0);
    transform: translate(0);
}

.inner {
    width: 100%;
}

.inner h4 {
    white-space: normal;
    margin-top: 0;
    min-height: auto;
    margin-bottom: .75rem;
    font-size: 20px;
    font-weight: bolder;
    position: relative;
}

.description {
    height: 40px;
    overflow: hidden;
    font-size: 14px;
    color: #666;
    word-break: break-word;
}

.fade {
    position: relative;
    left: 0;
    right: 0;
    text-align: right;
    top: -10px;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff);
}

.size {
    margin-top: 10px;
    font-style: normal;
    font-size: 17px;
    line-height: 19px;
    text-align: center;
    color: #FF6B00;
}

.buy {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    height: 40px;
}

.price_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.discount {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1;
}


.viewProduct::before {
    cursor: pointer;
    position: relative;
    top: 15px;
    content: "Подробнее";
    color: #FF6B00;
    font-size: 13px;
    border-bottom: 1px dashed #FF6B00;
}

.price {
    font-weight: 400;
    line-height: 1;
    position: relative;
    font-size: 14px;
    color: #aaa !important;
    text-decoration: line-through;
}

.quantity {
    display: flex;
    align-items: center;
}

.quantityText {
    font-size: 16px;
}

.rightBtn,
.leftBtn {
    width: 30px;
    color: #666 !important;
    outline: none;
    border: none;
}

.rightBtn:active,
.leftBtn:active {
    opacity: 0.6;
}

.leftBtn {
    border-radius: 10px 0 0 10px;
}

.rightBtn {
    border-radius: 0 10px 10px 0;
}

.buyBtn {
    border-radius: 15px;
    background-color: #FF6B00;
    color: #fff;
    outline: none;
    border: none;
    padding: 5px 12px;
}

.buyBtn:active {
    opacity: 0.6;
}
.detail-order {
    overflow: scroll;
    height: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    &__item {
        max-width: 200px;
        width: 100%;
        padding: 10px;
        background-color: #f1f1f1;
        border-radius: 10px;
        border: 1px solid #dcdde1;
        p {
            font-size: 18px;
            color: #636e72;
        }
    }  
    &__image{
        max-width: 190px;
        width: 100%;
        img {
            width: 100%;
            object-fit: cover;
        }
    }
}
.detail-order::-webkit-scrollbar {
    width: 0;
}

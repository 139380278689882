

a {
    text-decoration: none !important;
}

ul{
    list-style: none;
}

h5{
    font-weight: 900 !important;
}

html {
  scroll-behavior: smooth;
}
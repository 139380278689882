@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";

.footer-contact {
    display: flex;
    flex-direction: column;
    margin-top: -130px;
    margin-right: 28px;

    &__logo {
        img {
            width: 250px;
            height: 100;
            cursor: pointer;
        }
    }

    &__social {
        @include centerFlex;
    }

    &__location {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__icons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        a {
            background-color: #222;
            padding: 5px 8px;
            color: #fff;
            border-radius: 8px;
            font-size: 24px;
            cursor: pointer;
        }
        a:hover {
            color: #fff;
        }
    }
}

@media (max-width: 993px) {
    .footer-contact {
        margin: 0;
    }
}
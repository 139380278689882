@import "./assets/scss/variables.scss";
@import "./assets/scss/mixin.scss";

body {
  padding: 0;
  margin: 0;
  -webkit-padding: 0;
  -webkit-margin: 0;
  -moz-margin: 0;
  -o-margin: 0;
  background-color: $colorBlack !important;
  -moz-background-color: $colorBlack;
  -o-background-color: $colorBlack;
  -webkit-background-color: $colorBlack;
  position: relative;
  -webkit-position: relative;
  -moz-position: relative;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

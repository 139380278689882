@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";

.header-navbar {
  background-color: $colorWhite;

  .signIn {
    display: none;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    height: 73px;
    overflow: hidden;
  }

  &__icon {
    align-self: center;
    max-width: 250px;

    img {
      width: 100%;
    }
  }

  &__items {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;

    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0;
      width: 100%;
      margin: 0;
    }

    ul li {
      margin: 0;
      cursor: pointer;
      transition: 0.3s ease;
      -moz-transition: 0.3s ease;
      -webkit-transition: 0.3s ease;

      position: relative;
    }

    ul li a {
      font-size: $fontSize15;
      color: $colorOrange !important;
      text-decoration: none;
      cursor: pointer;
    }

    ul li a::before,
    ul li a::after {
      content: "";
      position: absolute;
      top: 1px;
      right: 0;
      height: 1.5px;
      width: 0;
      background: $colorOrange;
      transition: 400ms ease all;
      -webkit-transition: 400ms ease all;
      -moz-transition: 400ms ease all;
    }

    ul li a::before {
      left: inherit;
      bottom: inherit;
      top: 1px;
      right: 0;
    }

    ul li a::after {
      right: inherit;
      top: inherit;
      left: 0;
      bottom: 0;
    }

    ul li a:hover::before,
    ul li a:hover::after {
      width: 100%;
      transition: 800ms ease all;
      -moz-transition: 800ms ease all;
      -webkit-transition: 800ms ease all;
    }
  }

  &__burger-menu {
    z-index: 1;
    top: 98px;
    background: white;
    height: 0;
    visibility: hidden;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;

    &__content {
      text-align: center;

      ul {
        text-align: center;

        li {
          transition: none;
          -moz-transition: none;
          -webkit-transition: none;
          margin-bottom: 10px;
          display: flex;
          align-items: center;

          a {
            color: black;
            font-size: 0;
            cursor: pointer;
          }
        }
      }

      span {
        transition-delay: 0.15s;
        display: flex;
        justify-content: flex-start;
        margin-left: 29px;

        select {
          outline: none;
          background: none;
          border: none;
          color: transparent;
          font-size: 0;
        }
      }

      ol {
        margin-top: 20px;
        text-align: start;
        transition-delay: 0.15s;
        li {
          transition: none;
          display: inline-flex;
          align-items: center;
          margin-right: 10px;

          a {
            display: flex;
            align-items: center;

            img {
              width: 0;
              height: 0;
            }
          }
        }
      }
    }
  }

  &__burger-menu.open {
    height: 496px;
    visibility: visible;

    ul li a {
      font-size: $fontSize24;
    }

    ul li:hover a {
      color: $colorOrange;
    }

    select {
      color: $colorOrange;
      font-size: $fontSize24;
    }

    ol li a img {
      width: 40px;
      height: 40px;
    }
  }

  &__burger-menu-active {
    display: none;
  }

  .burger-menu {
    position: relative;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
  }

  .burger-menu-down {
    width: 32px;
    height: 4.8px;
    background: #000;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    -webkit-box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    display: none;
  }

  .burger-menu-down::before,
  .burger-menu-down::after {
    content: "";
    position: absolute;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
    height: 4.8px;
    background: #000;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    -moz-box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    -webkit-box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
  }

  .burger-menu-down::before {
    transform: translateY(-14px);
  }

  .burger-menu-down::after {
    transform: translateY(14px);
  }

  .burger-menu.open .burger-menu-down {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }

  .burger-menu.open .burger-menu-down::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .burger-menu.open .burger-menu-down::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }
}

@media screen and (max-width: 991px) {
  .header-navbar {
    .signIn {
      text-align: left;
      display: block;
      button {
        border: none;
        outline: none;
        border-radius: 100px;
        height: 100px;
        height: 38px;
        text-align: center;
        background-color: #ff6b00;
        color: #fff;
      }
    }
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__items {
      display: none;
    }

    &__burger-menu-active {
      display: block;
    }

    .burger-menu,
    .burger-menu-down {
      display: inline-block;
    }

    &__burger-menu {
      transform: translateY(0);
    }
  }
}

@media screen and (min-width: 991px) {
  .header-navbar__burger-menu {
    display: none !important;
  }
}

.private {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .user-name {
    color: $colorOrange !important;
    margin-right: 10px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400 !important;
    margin-top: 3px;
  }
}

.header-navbar-list__info {
  display: none;
}

@media (max-width: 991px) {
  .private {
    flex-direction: column;
    align-items: flex-start;

    .user-name {
      margin: 0 7px;
      font-size: 24px;
    }
  }
}

@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variables.scss";

.personal {
    padding-bottom: 180px;
    &__history {
        position: absolute;
        left: 95%;
        transform: translateX(-95%);
        top: 10px;
        color: #F47C0D;
        border-bottom: 1px dashed #ff6b00;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
    }
    &__title {
        text-align: center;
        font-size: 72px;
        font-weight: 700;
        line-height: 81px;
        letter-spacing: 0.01em;
        color: #ffffff;
    }
    &__wrapper {
        position: relative;
        display: flex;
        justify-content: space-around;
        margin: 200px auto;
        background-color: $colorWhite;
        padding: 80px 15px;
        @include borderRadius10;
    }
    &__left {
        background-color: #c4c4c4;
        padding-top: 100px;
        height: 450px;
        @include borderRadius10;
        overflow: hidden;
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__right {
        h2 {
            font-size: 48px;
            line-height: 54px;
        }
        label,
        input {
            font-size: 18px;
            line-height: 20px;
        }
        h2,
        label,
        input {
            color: $colorBlack;
        }
    }
    &__radio {
        display: flex;
        align-items: center;
        input[type="radio"] {
            outline: none;
            margin-left: 10px;
            width: 20px;
            height: 20px;
            appearance: none;
            @include borderRadiusCircle;
            border: 1px solid $colorOrange;
            position: relative;
        }
        input[type="radio"]:checked:before {
            width: 12px;
            height: 12px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            @include borderRadiusCircle;
            background-color: $colorOrange;
            content: "";
            display: inline-block;
            visibility: visible;
        }
    }
    &__notifications,
    &__btn-group {
        button {
            font-size: 18px;
            line-height: 20px;
            border-radius: 10px;
            outline: none;
            border: none;
            height: 64px;
            padding: 0 20px;
            img {
                margin-left: 8px;
            }
        }
    }
    &__btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__notifications {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        button {
            background-color: #ff6b00;
            color: $colorWhite;
        }
    }
    &__change,
    &__logout {
        margin: 0 5px;
        width: 215px;
    }
    &__change:active,
    &__logout:active{
        opacity: 0.6;
    }
    &__logout {
        background-color: #ff6b00;
        color: $colorWhite;
    }
    &__change {
        border: 1px solid #ff6b00 !important;
        background-color: $colorWhite;
    }
    &__right,
    &__form,
    &__form-control {
        display: flex;
        flex-direction: column;
    }
    &__form-control {
        max-width: 590px;
        margin-bottom: 40px;
        input[type='text'], input[type='email'], .personal__calendar {
            width: 100%;
            border-radius: 100px;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            background: #ffffff;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
            border: none;
            outline: none;
            padding: 10px 0 10px 1em;
        }
        label {
            font-size: 18px;
            line-height: 20px;
            color: #000000;
            margin-left: 10px;
        }
    }
}
@media (max-width: 995px) {
    .personal {
        &__wrapper {
            flex-direction: column;
            align-items: center;
            margin-top: 23px;
        }
        &__right {
            width: 100%;
        }
    }
}
@media (max-width: 800px) {
    .personal {
        &__title {
            font-size: 54px;
            line-height: 67px;
        }
        &__left {
            height: 350px;
        }
    }
}
@media (max-width: 500px) {
    .personal {
        padding-bottom: 64px;
        &__title {
            font-size: 24px;
            line-height: 27px;
        }
        &__right {
            margin-top: 20px;
            h2 {
                font-size: 24px;
                line-height: 27px;
            }
            label,
            input {
                font-size: 10px;
                line-height: 11px;
            }
        }
        &__notifications,
        &__btn-group {
            button {
                font-size: 9px;
                line-height: 10px;
                height: 28px;
                @include centerFlex;
                img {
                    width: 10px;
                }
            }
        }
        &__notifications {
            button {
                width: 100%;
            }
        }
    }
}

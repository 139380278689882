@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixin.scss';

.purchase-modal-list {
    &__item {
        display: flex;
        align-items: flex-end;
        margin: 11px 0;
        justify-content: space-between;
        .dashed {
            border-bottom: 2px dashed #000000;
            display: flex;
            align-content: flex-end;
            margin: 0 8px;
            width: 60%;
        }
        .text{
            font-size: $fontSize24;
            line-height: 27px;
            color: $colorBlack;
            margin: 0;
            padding: 0;
        }
    }
}

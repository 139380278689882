@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variables.scss";

.cart-item {
  padding: 5px;
  background: #fff;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  margin: 10px 0;
  display: flex;
  position: relative;
  width: 100%;

  &__delete {
    position: absolute;
    right: 30px;
    bottom: 170px;
    cursor: pointer;
    padding: 8px;
  }
  &__delete:hover, &__delete:active {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  }
  &__delete:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &__delete:active {
    background: rgba(0, 0, 0, 0.7);
  }

  &__images {
    width: 280px;
    margin-right: 30px;
    @include centerFlex;

    img {
      width: 100%;
    }
  }

  &__parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    h2 {
      text-align: start;
      font-size: 24px;
      line-height: 54px;
      color: $colorBlack;
    }
  }

  &__text {
    font-size: 32px;
    line-height: 36px;
    color: $colorOrange;
  }

  &__litle-title, &__count {
    font-size: 24px;
    line-height: 29px;
    color: $colorBlack;
  }

  &__group {
    display: flex;
    justify-content: space-between;
    padding: 45px 10px;
  }

  &__btn {
    display: flex;
    justify-content: space-around;
    align-items: center;

    span {
      cursor: pointer;
    }
  }

  &__counts,
  &__total-price,
  &__price {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1000px) {
  .cart-item {
    &__delete {
      bottom: 130px;
    }
    &__images {
      width: 140px;
      margin: 0;
    }

    &__parent {
      h2 {
        font-size: 28px;
        line-height: 34px;
      }
    }

    &__text {
      font-size: 18px;
      line-height: 20px;
    }

    &__litle-title, &__count {
      font-size: 14px;
      line-height: 17px;
    }
  }
}

@media (max-width: 540px) {
  .cart-item {
    flex-direction: column;
    align-items: center;
    &__delete {
      bottom: 210px;
      right: 10px;
    }

    &__parent {
      h2 {
        text-align: center;
      }
    }
  }
}

@import "./fonts-face.scss";
@import "./variables.scss";

@mixin Bold {
    font-family: "Philosopher", sans-serif;
    font-style: normal !important;
    font-weight: 700 !important;
}

@mixin Regular {
    font-family: "Philosopher", sans-serif;
    font-style: normal !important;
    font-weight: 400 !important;
}
@mixin Regular700 {
    font-family: "Philosopher", sans-serif;
    font-style: normal !important;
    font-weight: 700 !important;
}
@mixin Arial {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700 !important;
    font-style: normal !important;
}

@mixin Antonio {
    font-family: 'Antonio', sans-serif;
    font-weight: 700 !important;
    font-style: normal !important;
}
@mixin MontserratBold {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold !important;
    font-style: normal !important;
}
@mixin MontserratNormal {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal !important;
    font-style: normal !important;
}
@mixin Montserrat600 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600 !important;
    font-style: normal !important;
}
@mixin centerFlex  {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin displayFlex {
    display: flex;
}
@mixin borderRadiusCircle {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
}
@mixin borderRadius10 {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
}


